<template>

	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pa-0">
        <h1>Community Guidelines</h1>
      </div>
      <div class="dashboard__container--body mb-5" style="width:auto;">
					<h3>Information regarding Jump Staffing’s standard guidelines for use.</h3>
					<p>Our Community Guidelines reflect our company values and establish a common code for using the platform that helps us achieve our mission. Please take a moment to read our guidelines, as they are foundational in building trust, taking action, and having ownership of our community’s well-being.</p>
				
					<h3 class="mt-3">Standards for all of us</h3>
					<h4 class="mt-3">Focus on our Community</h4>
					<p>Our platform is people-driven and community-focused. Our guidelines are developed to provide a safe, respectful, and productive environment for our Partners and Professionals to achieve success. By making people our priority, we’re able to create a culturally-diverse marketplace on our platform connecting individuals and experiences. Whether you are a Partner, a Professional, or an employee of Jump Staffing, our guidelines reflect our values and our collective responsibility: without each other, Jump Staffing would not be able to achieve our mission.</p>
					<h4 class="mt-3">Empathy and Continued Learning</h4>
					<p>In a perfect world, we would not make mistakes. Jump Staffing understands that mistakes happen, and we are willing to learn from them and continue to thrive. One of our core values is empathy, trust, and candor. We understand that you may encounter an unforeseen circumstance, or simply have a bad day. We trust that you’ll make the right decision when necessary, knowing that everyone is impacted. We also believe that your expertise and professionalism in your industry provides a high level of awareness about the importance of behaving respectfully, carefully, and offering the undeniably best service to your customers.</p>
					<h4 class="mt-3">Discrimination and Harassment</h4>
					<p>We aspire to create economic opportunity for all, not for the few. Jump Staffing has a zero-tolerance policy for discrimination and harassment. We do not permit discrimination against anyone based on traits such as their age, color, disability, gender identity, marital status, national origin, race, religion, sex, sexual orientation, or any other characteristic protected under the relevant laws. Any forms of harassment including but not limited to verbal, sexual, or physical threats should be reported to Jump Staffing Support immediately and, in some instances, the local authorities.</p>
					<h4 class="mt-3">Trust and Safety</h4>
					<p>Trust and Safety are critical factors for our success and well-being. It is the responsibility of our community to ensure that we are engaging with each other with integrity and accountability to our individual and collective safety. Preventing safety issues includes but is not limited to:</p>
					<ul>
						<li>Complying with Federal, State, Provincial, and Local food, safety, and labor regulations.</li>
						<li>Wearing the appropriate attire for your position.</li>
						<li>Carefully executing your responsibilities on every shift.</li>
						<li>Not engaging in horseplay, scuffling, or any activity that may impact the safety of others.</li>
						<li>Safely handling tools and equipment and avoiding tampering with any work-related materials.</li>
						<li>Not bringing any firearms or other weapons to shifts.</li>
						<li>Abstaining from all intoxicating and performance impairing substances.</li>
						<li>Reporting any illegal activities witnessed during the shift(e.g. theft, harassment).</li>
					</ul>
				<h4 class="mt-3">How we enforce our Guidelines</h4>
				<p>Having clear guidelines provides the foundation for success. We recognize that every situation varies - if you encounter a situation that needs to be addressed please contact Jump Staffing Support with specific details about your experience, and we will carefully consider your situation and the parties involved. Proven violation of Jump Staffing’s Community Guidelines will result in loss of access to our platform. If you have any questions about Jump Staffing’s policies, please click <a href="/workers">here</a> for more information.</p>
				<h3 class="mt-3">Standards for our Partners</h3>
				<h4 class="mt-3">Create a Welcoming Environment</h4>
				<p>At Jump Staffing, we strive to connect you with high quality, reliable Professionals without the hassle of having to find them on your own. It is important that we provide a safe and warm environment for Professionals to do their best work. We want Professionals to leave their shifts more excited than when they arrived. We ask that you help Professionals feel welcomed to your location and a part of your team as much as possible.</p>
				<h4 class="mt-3">Industry Best Practices</h4>
				<p>Please comply with Federal, State, Provincial, and Local food, labor, and safety regulations. The safety and comfort of Professionals working shifts and our Partners' ability to book them is Jump Staffing’s top priority. Any Partner not complying with best practices may be subject to review and loss of access to Jump Staffing’s platform.</p>
				<h4 class="mt-3">Keeping our Community Strong</h4>
				<p>In order to keep a healthy, strong community, we ask that everyone do their part. Providing appropriate feedback for Professionals and booking reliable shifts is essential to our growth. Properly maintaining your business environment is a critical factor in Professionals wanting to work your shifts and provide the highest quality of service.</p>
				<h3 class="mt-3">Standards for Professionals</h3>
				<h4 class="mt-3">Being Reliable</h4>
				<p>Please be mindful that picking up a shift is your commitment to our Partners, your fellow Professionals, and to Jump Staffing that you are going to work the shift. We do understand that life happens and encourage you to cancel a shift as soon as possible when necessary. We take no-shows (not canceling at all) very seriously. Not arriving at your shift as scheduled results in our Partners losing labor needed, and prevents other Professionals in our community from having an opportunity to work. A no-show will result in permanent account deactivation.</p>
				<h4 class="mt-3">Respecting Privacy</h4>
				<p>Please be mindful of sharing details of shifts or Partner information on your social media. You have agreed to keep proprietary business information about Partners confidential. Further, some shifts are private events and require additional Non-Disclosure Agreements (NDA) to be signed prior to working them. Phones are not allowed during shift hours so any images taken may violate Jump Staffing guidelines and could lead to loss of access to our platform.</p>
				<h4 class="mt-3">Misuse of the App</h4>
				<p>Sharing accounts or impersonating another Professional and creating more than one account on the platform is fraud. As part of Trust & Safety, it’s critical that the person showing up for work is the person on the profile. Any accounts engaging in any fraudulent activity will be deactivated.</p>
			</div>
		</div>
	</div>
</template>


<script>
export default {
  name: 'guidelines'
}
</script>